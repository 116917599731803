import { createBrowserRouter, RouterProvider } from "react-router-dom";
import About from './pages/About';
import Projects from './pages/Projects';
import Skills from './pages/Skills';
import Contact from './pages/Contact';
import NotFoundPage from './components/NotFoundPage'; // Import NotFoundPage component
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Experience from "./pages/Experience";

const router = createBrowserRouter([
  { path: '/', element: <About /> },
  { path: '/projects', element: <Projects /> },
  { path: '/experience', element: <Experience /> },
  { path: '/skills', element: <Skills /> },
  { path: '/contact', element: <Contact /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  // Add a route for the NotFoundPage component
  { path: '*', element: <NotFoundPage /> }, // Match any route that hasn't been defined above
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
