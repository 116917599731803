import { Container, Row, Col, Button } from 'react-bootstrap';
import experienceBackgroundImage from '../assets/images/backgrounds/experience.jpg';

const ExperienceBackground = () => {
  return (
    <div className="page-header min-vh-80" style={{backgroundImage: `url(${experienceBackgroundImage})`}} loading="lazy">
      <span className="mask bg-gradient-dark opacity-8"></span>
      <Container>
        <Row>
          <Col lg={12} className="d-flex justify-content-start flex-column">
            <h1 className="text-white text-start mb-4">My Experience</h1>
            <h3 className="text-white text-start fw-light mb-0 ms-1">Worked with teams in startups and some solo projects.</h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExperienceBackground;
