
import React from 'react';
import Layout from '../components/Layout';
import ExperienceBackground from '../components/ExperienceBackground';
import ExperienceTimeline from '../components/ExperienceTimeline';

const Experience = () => {
  return (
    <Layout>
      <ExperienceBackground />
      <ExperienceTimeline />
    </Layout>
  );
};

export default Experience;
