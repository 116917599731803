import React from 'react';
import { Container, Paper, Typography, Box } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@mui/lab';
import { EXPERIENCE } from '../data/data.js';

function parseYear(dateStr) {
  const matches = dateStr.match(/\b(20\d{2})\b/g);
  if (!matches && dateStr.toLowerCase().includes('present')) {
    return 3000;
  }
  if (matches) {
    const years = matches.map(str => parseInt(str, 10));
    return Math.max(...years);
  }
  if (dateStr.toLowerCase().includes('present')) {
    return 3000;
  }
  return -1;
}

const ExperienceTimeline = () => {
  // Create a sorted copy (descending by year)
  const sortedExperience = [...EXPERIENCE].sort(
    (a, b) => parseYear(b.date) - parseYear(a.date)
  );

  return (
    <section style={{ padding: '2rem 0' }}>
      <Container maxWidth="md">
        {/* <Typography variant="h4" gutterBottom>
          My Experience
        </Typography> */}
        <Timeline position="right">
          {sortedExperience.map((exp, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ flex: 0.2, display: { xs: 'none', md: 'block' } }}
                align="right"
                variant="body2"
                color="text.primary"
              >
                {exp.date}
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index < sortedExperience.length - 1 && <TimelineConnector />}
              </TimelineSeparator>

              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Box sx={{ display: { xs: 'block', md: 'none' }, mb: 1 }}>
                  <Typography variant="caption" color="text.secondary">
                    {exp.date}
                  </Typography>
                </Box>

                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography variant="h6" component="h1">
                    {exp.company}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {exp.role}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    <strong>Tech Stack:</strong> {exp.techStack}
                  </Typography>
                  <Typography variant="body2" paragraph>
                    {exp.description}
                  </Typography>
                  {exp.link && (
                    <Typography variant="body2" color="primary">
                      <a
                        href={exp.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {exp.link}
                      </a>
                    </Typography>
                  )}
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Container>
    </section>
  );
};

export default ExperienceTimeline;
